export function processOptions(value: any) {
  let options

  if (typeof value === 'function') {
    // Simple options (callback-only)
    options = {
      callback: value,
    }
  } else {
    // Options object
    options = value
  }
  return options
}

export function throttle(callback: any, delay: number | undefined, options: { leading: (state: any) => any }) {
  let timeout: number | null | undefined
  let lastState: any
  let currentArgs: any[]
  const throttled = (state: any, ...args: any[]) => {
    currentArgs = args
    if (timeout && state === lastState) return
    let leading = options.leading

    if (typeof leading === 'function') {
      // @ts-ignore
      leading = leading(state, lastState)
    }
    if ((!timeout || (state !== lastState)) && leading) {
      callback(state, ...currentArgs)
    }
    lastState = state
    // @ts-ignore
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      callback(state, ...currentArgs)
      timeout = 0
    }, delay)
  }

  throttled._clear = () => {
    // @ts-ignore
    clearTimeout(timeout)
    timeout = null
  }
  return throttled
}

export function deepEqual(val1: { [x: string]: any }, val2: { [x: string]: any }) {
  if (val1 === val2) return true
  if (typeof val1 === 'object') {
    for (const key in val1) {
      if (!deepEqual(val1[key], val2[key])) {
        return false
      }
    }
    return true
  }
  return false
}
