import ObserveVisibility from "./directives/observe-visibility";

const ObserveVisibilityDirective = {
    install(app) {
        app.directive('observe-visibility', ObserveVisibility)
    }
};

// Automatic installation if Vue has been added to the global scope.
if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.directive('observe-visibility', ObserveVisibility);
}

export default ObserveVisibilityDirective;
export {ObserveVisibility}